import Color from "../../../assets/colors";
import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";

// Accordian Components //
export const AccordianDetailsStyled = styled(AccordionDetails)`
  && {
    flex-direction: column;
    align-items: center;
  }
`;

export const AccordianStyled = styled(Accordion)`
  && {
  }
`;

// Checkbox Components //
export const CheckboxStyled = styled(Checkbox)`
  && {
    color: ${Color.black} !important;
  }
`;

// Typography Components //
export const TitleStyled = styled(Typography)`
  && {
    font-weight: bold;
    text-transform: capitalize;
  }
`;

export const ContentTextStyled = styled(Typography)`
  && {
    text-transform: capitalize;
  }
`;
