import { Checkbox, FormControlLabel } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useRouter } from "next/router";
import posthog from "posthog-js";
import { Fragment, useLayoutEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";
import { assetDomain } from "../../../config/config";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import useTriggerSnackbar from "../../../hooks/useTriggerSnackbar";
import {
  showInfoDialogActivelyAvailable,
  updateProfilePreviewDialog,
  updateResumeConfirmationDialog,
  updateSignUpProfileDialog,
} from "../../../redux/actions/dialog_action";
import { applyJob } from "../../../redux/actions/job_action";
import {
  newResumeUpload,
  updateOnboardingInfo,
} from "../../../redux/actions/user_action";
import { DirectSignUpFormUserParams } from "../../jobs/SecondaryVisitor/BaseDialog/types";
import FormTextField from "../../jobs/SecondaryVisitor/FormTextField/FormTextField";
import ResumeDropzone from "../../jobs/SecondaryVisitor/ResumeDropzone/ResumeDropzone";
import ActivelyAvailableDialog from "../../profile/ActivelyAvailableDialog/ActivelyAvailableDialog";
import {
  CheckIdleIcon,
  CheckLabel,
  CheckedIcon,
  CloseButton,
  CloseRoundedIconStyled,
  DialogActionsStyled,
  DialogContentStyled,
  DialogContentWrapper,
  DialogStyled,
  DialogTitleContainer,
  DialogTitleStyled,
  EmptyContainer,
  HeaderCaption,
  IOSSwitch,
  ImageGif,
  InformationCircleIconStyled,
  LabelStyled,
  LoadingButtonStyled,
  StyledLink,
  SuccessBodyTypography,
  SuccessContainer,
  SuccessTextWrapper,
  SuccessTitleContainer,
  SuccessTitleTypography,
  SwitchContainer,
  SwitchDescription,
  SwitchHeaderContainer,
  SwitchTitle,
  SwitchTitleContainer,
  WhiteButton,
  useStyles,
} from "./styles";

export default function SignUpProfileDialog(props: any) {
  const dispatch: any = useDispatch();
  const router = useRouter();
  const [triggerSnackbarFunc] = useTriggerSnackbar();
  const open = useSelector(
    (state: any) => state?.dialog?.showSignUpProfileDialog
  );
  const selectedJobId = useSelector((state: any) => state.jobs?.selectedJobId);
  const externalJobUrl = useSelector(
    (state: any) => state.jobs?.job?.externalJobUrl
  );

  const user = useSelector((state: any) => state?.user?.user);
  const resume = useSelector((state: any) => state?.user?.resume);
  const mobile = useMediaQuery("(max-width:768px)");
  const [ref, inView, _] = useInView({ threshold: mobile ? 0.2 : 1 });
  const forms = ["Name", "Mobile Number", "Nationality", "Current Location"];
  const [resumeUploaded, setResumeUploaded] = useState(false);
  const [exist, setExist] = useState(false);
  const [formState, setFormState] = useState<"pending" | "loading" | "success">(
    "pending"
  );
  const [openForJob, setOpenForJob] = useState(true);
  const [showSwitchContainer, setShowSwitchContainer] = useState(false);
  const [userFormData, setUserFormData] = useState<DirectSignUpFormUserParams>({
    name: "",
    validName: true,
    phone: "",
    validPhone: true,
    nationality: "",
    validNationality: false,
    currentLocation: "",
    validCurrentLocation: false,
    isFreshGrad: false,
  });

  const confettiGif = `${assetDomain}/gifs/confetti.gif`;
  const classes = useStyles();

  useLayoutEffect(() => {
    setShowSwitchContainer(inView ? false : true);
  }, [inView]);

  function handleSubmit() {
    setFormState("loading");

    const userProfile = {
      name: userFormData.name,
      mobileNumber: userFormData.phone,
      nationality: userFormData.nationality,
      freshGrad: userFormData.isFreshGrad,
      email: user.email,
      currentLocation: userFormData.currentLocation,
      talentSearch: openForJob,
    };

    dispatch(
      newResumeUpload({
        resume: resume,
        state: "approved",
        canCancel: false,
        user_resume: "", // to indicate first time upload
      })
    ).then((resumeUploadRes: any) => {
      if (resumeUploadRes?.type === "UPDATE_USER_CV_FAILED") {
        triggerSnackbarFunc({
          snackbarMessage: resumeUploadRes?.message,
          severity: "error",
        });
      } else {
        // Upload resume success
        dispatch(updateOnboardingInfo(userProfile)).then(
          (updateProfileRes: any) => {
            if (updateProfileRes?.type === "UPDATE_ONBOARDING_INFO_FAILED") {
              triggerSnackbarFunc({
                snackbarMessage:
                  "Something went wrong, please try again later.",
                severity: "error",
              });
            } else if (
              updateProfileRes?.type === "UPDATE_ONBOARDING_INFO_SUCCEED"
            ) {
              const { user } = updateProfileRes || {};
              // // identify user on posthog after is done onboarding
              posthog.capture("update_user_properties", {
                distinctId: user?.id,

                $set: {
                  uuid: user?.id,
                  age: parseInt(user?.age ?? 0),
                  gender: user?.gender,
                  nationality: user?.nationality,
                  is_actively_available: user?.activelyAvailable,
                  has_resume: user?.resume ? true : false,
                  current_location: user?.currentLocation,
                  is_fresh_grad: user?.freshGrad,
                  is_onboarding_completed: user?.onboardingComplete,
                  signup_platform_device_os: user?.signupPlatformDeviceOs,
                  track_ids: user?.trackIds,
                },
              });

              sendTrackingEvent({
                event: "CE_click-continue-finish-signup-onboarding",
              });
              if (selectedJobId) {
                // Auto apply job
                dispatch(
                  applyJob({ jobId: selectedJobId, canCancel: false })
                ).then((applyJobRes: any) => {
                  if (applyJobRes.type === "APPLY_JOB_SUCCEED") {
                    if (externalJobUrl) {
                      window.open(externalJobUrl, "_blank");
                    }

                    setFormState("success");
                  } else {
                    triggerSnackbarFunc({
                      snackbarMessage:
                        "Failed to auto apply job for you, please apply it again with your account.",
                      severity: "error",
                    });
                    setFormState("success");
                  }
                });
              } else {
                setFormState("success");
              }
            } else {
              triggerSnackbarFunc({
                snackbarMessage:
                  "Something went wrong, please try again later.",
                severity: "error",
              });
            }
          }
        );
      }
    });
  }

  function isValidUserFormData() {
    return (
      userFormData.validCurrentLocation &&
      userFormData.validName &&
      userFormData.validNationality &&
      userFormData.validPhone &&
      userFormData.name.length > 0 &&
      isPossiblePhoneNumber(userFormData.phone) &&
      resumeUploaded
    );
  }

  function handleCloseDialog() {
    dispatch(updateSignUpProfileDialog(false));
    router.reload();
  }

  function handleCompleteProfileClick() {
    dispatch(updateSignUpProfileDialog(false));
    dispatch(updateProfilePreviewDialog(true));
  }

  function handleExploreJobsClick() {
    dispatch(updateSignUpProfileDialog(false));
    sendTrackingEvent({
      event: "CE_click-explore-jobs-short-onboarding",
    });
    router.push("/jobs");
  }

  function handleBackClick() {
    dispatch(updateSignUpProfileDialog(false));
    dispatch(updateResumeConfirmationDialog(true));
  }

  function handleInfoIconClick() {
    dispatch(showInfoDialogActivelyAvailable(true));
  }

  function toggleSwitch() {
    setOpenForJob((prev) => !prev);
  }

  return (
    <DialogStyled open={open} success={formState === "success"}>
      <ActivelyAvailableDialog />
      {formState === "success" && (
        <CloseButton onClick={handleCloseDialog}>
          <CloseRoundedIconStyled />
        </CloseButton>
      )}
      {/* Header */}
      <DialogTitleContainer>
        <DialogTitleStyled>
          {formState === "success"
            ? "Your account has been created!"
            : "Finish signing up"}
          {formState !== "success" && (
            <HeaderCaption>
              We will need some basic info from you, as well as your resume.
            </HeaderCaption>
          )}
        </DialogTitleStyled>
      </DialogTitleContainer>

      {/* Content */}
      <DialogContentStyled
        className={classes.scrollBar}
        success={formState === "success"}
      >
        <DialogContentWrapper className={classes.scrollBar}>
          {formState === "success" ? (
            <SuccessContainer>
              <SuccessTitleContainer>
                <SuccessTitleTypography>
                  Welcome to Hiredly!
                </SuccessTitleTypography>
                <ImageGif src={confettiGif} alt="GIF" />
              </SuccessTitleContainer>

              <SuccessTextWrapper>
                <SuccessBodyTypography>
                  Complete your profile effortlessly using your resume.
                </SuccessBodyTypography>
                <SuccessBodyTypography>
                  We've got your back – your information is pre-filled for your
                  convenience. Let's showcase your journey together!
                </SuccessBodyTypography>
              </SuccessTextWrapper>
            </SuccessContainer>
          ) : (
            <Fragment>
              {forms.map((form, index) => {
                return (
                  <FormTextField
                    key={`form-textfield-${index}`}
                    name={form}
                    userParams={userFormData}
                    setUserParams={setUserFormData}
                    exist={exist}
                  />
                );
              })}

              <FormControlLabel
                control={
                  <Checkbox
                    checkedIcon={<CheckedIcon />}
                    icon={<CheckIdleIcon />}
                  />
                }
                label={<CheckLabel>I’m a fresh graduate/ student</CheckLabel>}
              />

              <EmptyContainer>
                <ResumeDropzone
                  ref={ref}
                  complete={resumeUploaded}
                  setComplete={setResumeUploaded}
                />
              </EmptyContainer>

              <EmptyContainer sx={{ marginTop: "20px" }}>
                <LabelStyled disclaimer="true">
                  By clicking “Next” below, you acknowledge that you have read
                  and agreed to Hiredly’s{" "}
                  <StyledLink href="/terms-and-conditions">
                    Terms & Conditions
                  </StyledLink>{" "}
                  and{" "}
                  <StyledLink href="/privacy-policy">Privacy Policy</StyledLink>
                  .
                </LabelStyled>
              </EmptyContainer>
            </Fragment>
          )}
        </DialogContentWrapper>
        {formState !== "success" && (
          <SwitchContainer show={showSwitchContainer}>
            <SwitchHeaderContainer>
              <SwitchTitleContainer>
                <SwitchTitle>Open to Opportunities</SwitchTitle>
                <InformationCircleIconStyled
                  role="button"
                  aria-label="open-talent-search-dialog"
                  onClick={handleInfoIconClick}
                />
              </SwitchTitleContainer>

              {!mobile && (
                <SwitchDescription>
                  Let employers discover your profile and reach out to you!
                </SwitchDescription>
              )}
              <IOSSwitch checked={openForJob} onClick={toggleSwitch} />
            </SwitchHeaderContainer>
            {mobile && (
              <SwitchDescription>
                Let employers discover your profile and reach out to you!
              </SwitchDescription>
            )}
          </SwitchContainer>
        )}
      </DialogContentStyled>

      {/* Action */}
      <DialogActionsStyled>
        {formState === "success" ? (
          <Fragment>
            <WhiteButton onClick={handleExploreJobsClick}>
              Explore Jobs
            </WhiteButton>
            <LoadingButtonStyled
              loading={false}
              onClick={handleCompleteProfileClick}
            >
              Complete {!mobile && "Your"} Profile
            </LoadingButtonStyled>
          </Fragment>
        ) : (
          <Fragment>
            <WhiteButton onClick={handleBackClick}>Back</WhiteButton>
            <LoadingButtonStyled
              loading={formState === "loading"}
              onClick={handleSubmit}
              disabled={!isValidUserFormData()}
            >
              {formState === "loading" ? "" : "Continue"}
            </LoadingButtonStyled>
          </Fragment>
        )}
      </DialogActionsStyled>
    </DialogStyled>
  );
}
