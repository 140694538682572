import BriefcaseIcon from "@heroicons/react/24/outline/BriefcaseIcon";
import BuildingOfficeIcon from "@heroicons/react/24/outline/BuildingOfficeIcon";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import SparklesIcon from "@heroicons/react/24/solid/SparklesIcon";
import { Chip, OutlinedInput } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grid from "@mui/material/Grid";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import { default as Link, default as MaterialUiLink } from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled as styling } from "@mui/material/styles";
import withTheme from "@mui/styles/withTheme";
import styled, { css } from "styled-components";
import Color from "../../../assets/colors";
import { FontSize } from "../../../assets/font";
import MYFlag from "../../../public/images/MY.svg";
import SGFlag from "../../../public/images/SG.svg";

// Menu List Component //
export const MenuListStyled = styling(MenuList)({
  overflow: "hidden !important",
  textOverflow: "ellipsis !important",
});

// Paper Component //
export const PaperStyled = styling(Paper)({
  overflow: "hidden",
  textOverflow: "ellipsis !important",
});

// Stack Component //
export const StackStyled = styling(Stack)({});

// Toolbar Components //
// export const ToolbarStyled = withTheme(styled(Toolbar)`
//   && {
//     display: flex;
//     justify-content: space-between;
//   }
// `);

// Grow Components //
// export const GrowStyled = withTheme(styled(Grow)`
//   && {
//     z-index: 20;
//     width: 210px;
//     margin: 0 10px 0 0;
//     transform-origin: ${(props) =>
//       props.placement === "bottom" ? "center top" : "center bottom"};
//   }
// `);

// Menu Item Components //
export const MenuItemStyled = withTheme(styled(MenuItem)`
  && {
    font-weight: ${(props) => (props.$font_bold ? "bold" : null)};
    cursor: ${(props) => (props.$cursor ? "default" : null)};
    width: 100%;
    height: 2rem;
    padding: 0 0 0 15px;
    justify-content: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`);

// Grid Components //
export const GridStyled = withTheme(styled(Grid)`
  && {
    position: relative;
    height: 20px;
    width: 140px;
    cursor: pointer;
  }
`);

export const LogoWrapper = styling(Grid)((props) => ({
  [props.theme.breakpoints.down("tablet")]: {
    width: "70vw",
    transform: `translate(0px, -12px)`,
    left: "15%",
    right: "10%",
    position: "fixed",
  },
}));

export const HoverGrid = styling(Grid)({
  //
});

export const GridFlexStyled = withTheme(styled(Grid)`
  && {
    display: flex;
    z-index: 1;
    align-items: center;
    justify-content: ${(props) => (props.$flex_end ? "flex-end" : "center")};
    width: fit-content;
    position: absolute;
    top: 50%;
    left: 2%;
    transform: translate(0, -50%);

    ${(props) => props.theme.breakpoints.down("lg")} {
      position: absolute;
      top: 50%;
      left: 130px;
      transform: translate(-50%, -50%);
    }

    ${(props) => props.theme.breakpoints.down("tablet")} {
      left: 0px;
      transform: none;
      display: ${(props) =>
        props.$show_search_bar === "true" && !props.$transparent
          ? "none"
          : "block"};
    }
  }
`);

export const SearchBarGridFlexStyled = withTheme(styled(Grid)`
  && {
    position: relative;
    display: flex;
    justify-content: center;
    align-self: center;
    width: 300px;
    height: 100%;
    justify-content: ${(props) => (props.flexend ? "flex-end" : "center")};
    transition: opacity 0.3s, visibility 0.3s;
    opacity: ${(props) =>
      props.$show_search_bar === "true" ||
      props.$search_bar_clicked_scrolly != 0
        ? "1"
        : "0"};
    visibility: ${(props) =>
      props.$show_search_bar === "true" ||
      props.$search_bar_clicked_scrolly != 0
        ? "visible"
        : "hidden"};

    ${(props) => props.theme.breakpoints.down("lg")} {
      max-height: 40px;
      width: 100%;
      max-width: 450px;
      display: ${(props) =>
        props.$show_search_bar === "true" ? "flex" : "none"};
      padding: 0 8px;
    }

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      width: 100%;
      min-width: 150px;
    }
  }
`);

export const GridFlexButtons = withTheme(styled(Grid)`
  && {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0%, -50%);
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.$flexend ? "flex-end" : "center")};
    width: fit-content;
    margin-right: 0.75rem;
    z-index: 10;

    ${(props) => props.theme.breakpoints.down("tablet")} {
      display: none;
    }
  }
`);

export const AppBarContentGrid = withTheme(styled(Grid)`
  && {
    color: ${Color.black};
    position: relative;
    width: auto;
  }
`);

export const GridSignInMobile = withTheme(styled(Grid)`
  && {
    white-space: ${(props) => (props.$nowrap ? "nowrap" : null)};
  }
`);

export const FilterGrid = withTheme(styled(Grid)`
  && {
    opacity: ${(props) =>
      props.$show_filter || props.$search_bar_clicked_scrolly != 0 ? "1" : "0"};
    visibility: ${(props) =>
      props.$show_filter || props.$search_bar_clicked_scrolly != 0
        ? "visible"
        : "hidden"};
    height: ${(props) =>
      props.$show_filter || props.$search_bar_clicked_scrolly != 0
        ? "90px"
        : "0"};
    transform: ${(props) =>
      props.$show_filter || props.$search_bar_clicked_scrolly != 0
        ? "scale(1.0)"
        : "scale(1.3)"};
    transition: opacity 0.5s, visibility 0.5s, transform 0.5s;
    z-index: -1;

    ${(props) => props.theme.breakpoints.down("mobileL")} {
      display: none;
    }
  }
`);

// Material UI Link Components //
export const MaterialUiLinkStyled = styling(MaterialUiLink)((props) => ({
  textDecoration: props.active === "true" ? "underline" : "none",
  textUnderlineOffset: "1.3rem",
  textDecorationThickness: "6px",
}));

export const NavbarLinksStyled = styling(Button)((props) => ({
  fontSize: "16px",
  fontWeight: "400",
  textTransform: "capitalize",
  whiteSpace: "nowrap",
  margin: "0 0 0 1.5rem",
  textDecoration: "none",

  "&:hover": {
    fontWeight: "700",
    backgroundColor: "transparent",

    textDecoration: "underline",
    textUnderlineOffset: "1.3rem",
    textDecorationThickness: "6px",

    textDecoration: "none",
    transition: "all 0.125s ease-in-out",
  },

  ...(props.$active && {
    fontWeight: "700",
    backgroundColor: "transparent",

    textDecoration: "underline",
    textUnderlineOffset: "1.3rem",
    textDecorationThickness: "6px",
  }),

  [props.theme.breakpoints.down("lg")]: {
    fontSize: "12px",
    margin: "0",
    display: props.$type === "for-you" ? "flex" : "none",
  },

  [props.theme.breakpoints.down("tablet")]: {
    display: "none",
  },
}));
// Button Components //

export const NavBarButton = withTheme(styled(Button)`
  && {
    margin-left: ${(props) => (props.$sign_up === "true" ? "1rem" : null)};
    margin-right: ${(props) => (props.$sign_up === "true" ? "2.5rem" : null)};
    border-radius: 0.5rem;
    text-transform: capitalize;
    width: 6.25rem;
    height: 2.5rem;
    font-weight: 400;
    font-size: 0.875rem;

    ${(props) => props.theme.breakpoints.down("lg")} {
      margin-right: ${(props) => (props.$sign_up === "true" ? "1.5rem" : null)};
      font-size: 12px;
      height: 1.75rem;
      width: 5rem;
    }
  }
`);

export const EmployerButton = styling(Button)((props) => ({
  borderRadius: "0.5rem",
  textTransform: "capitalize",
  height: "2.5rem",
  fontWeight: "400",
  color: Color.hiredlyPurple,
  fontSize: "0.875rem",

  [props.theme.breakpoints.down("lg")]: {
    fontSize: "0.75rem",
    height: "1.75rem",
  },
}));

export const ButtonStyled = withTheme(styled(Button)`
  && {
    text-transform: capitalize;
    margin: ${(props) =>
      props.$margin_right === "true"
        ? "0 1.75rem"
        : props.$for_employer === "true"
        ? "0 3rem 0 0"
        : "0"};
    font-weight: ${(props) => (props.$active ? "700" : "400")};
    white-space: nowrap;
    font-size: 0.875rem;
    background-color: ${(props) =>
      props.$solid_purple ? `${Color.hiredlyPurple}` : ""};

    &:hover {
      font-weight: 700;
      background-color: ${(props) =>
        props.$white ? `${Color.darkPurple}` : "transparent"};

      & .MuiButton-label {
        border-bottom: ${(props) =>
          props.$no_underline ? null : `1px solid ${Color.hiredlyPurple}`};
      }
    }

    & .MuiButton-label {
      border-bottom: ${(props) =>
        props.$active ? `1px solid ${Color.hiredlyPurple}` : null};
    }

    ${(props) =>
      props.for_employer == "true" &&
      css`
        display: ${(props) =>
          props.$show_search_bar === "true" ? "none" : "block"};
      `}

    ${(props) => props.theme.breakpoints.down("lg")} {
      display: none;
      font-size: 0.75rem;

      ${(props) =>
        props.$for_employer &&
        props.$show_search_bar !== "true" &&
        css`
          display: block;
        `}

      ${(props) =>
        props.$desktop_sign_in &&
        css`
          display: block;
        `}
    }

    ${(props) => props.theme.breakpoints.down("md")} {
      ${(props) =>
        props.$small &&
        css`
          width: 100px;
          padding: 0.1875em 0.9375em;
        `}
    }
  }
`);

export const MobileLoginButtonStyled = withTheme(styled(Button)`
  && {
    color: ${Color.hiredlyPurple};
    text-transform: capitalize;
    display: none;
    font-size: ${FontSize.desktopCaption};
    border-radius: 0.5rem;
    padding: 0 20px;
    width: 6.25rem;
    height: 2.5rem;
    left: 22px;
    bottom: 2px;

    &:hover {
      color: ${Color.hiredlyPurple};

      & .MuiButton-label {
        border-bottom: ${(props) =>
          props.$no_underline ? null : `1px solid ${Color.hiredlyPurple}`};
      }
    }

    ${(props) => props.theme.breakpoints.down("tablet")} {
      display: ${(props) => (props.$hide_button ? "none" : "block")};
    }
  }
`);

// Div Components //
export const ProfileButtonStyled = withTheme(styled.div`
  && {
    cursor: pointer;
    position: relative;
    padding: 1.5px;
    background-color: ${Color.white};
    border-radius: 50px;
  }
`);

export const StagingTextDiv = withTheme(styled.div`
  && {
    position: absolute;
    right: -40px;
    font-size: 8px;
    bottom: -4;
    top: 12px;
  }
`);

export const ProfileMenuContainer = withTheme(styled.div`
  && {
    ${(props) => props.theme.breakpoints.down("tabletS")} {
      display: none;
    }

    ${(props) => props.theme.breakpoints.up("tabletS")} {
      position: absolute;
      right: 24px;
    }
  }
`);

export const MobileProfileMenuContainer = withTheme(styled.div`
  && {
    display: none;
    ${(props) => props.theme.breakpoints.down("tabletS")} {
      display: block;
    }
  }
`);

// AppBar Components //
export const AppBarStyled = withTheme(styled(AppBar)`
  && {
    background-color: hsla(0, 0%, 100%, 0.8);
    backdrop-filter: blur(0.25rem);
    padding: ${(props) =>
      props.$show_dropdown_searchbar === "true" ? "10px 0" : ""};

    ${(props) => props.theme.breakpoints.down("tabletS")} {
      ${(props) =>
        props.hide_box_shadow == "true" &&
        css`
          box-shadow: none;
          border-bottom: 1px solid lightgrey;
        `}
    }
  }
`);

export const Logo = withTheme(styled.img`
  && {
    width: inherit;
  }
`);

// Icon Components //
export const SparkleStyled = styling(SparklesIcon)((props) => ({
  width: "1rem",
  height: "1rem",
  color: Color.hiredlyPurple,
  marginLeft: "0.5rem",
}));

export const JobsIcon = styling(BriefcaseIcon)((props) => ({
  height: "24px",
  width: "24px",

  [props.theme.breakpoints.down("sm")]: {
    height: "18px",
    width: "18px",
  },
}));

export const CompanyIcon = styling(BuildingOfficeIcon)((props) => ({
  height: "24px",
  width: "24px",

  [props.theme.breakpoints.down("sm")]: {
    height: "18px",
    width: "18px",
  },
}));

// Icon Button Components //
// export const IconButtonStyled = withTheme(styled(IconButton)`
//   && {
//     display: none;

//     ${(props) => props.theme.breakpoints.down("lg")} {
//       display: block;
//       padding-bottom: 0px;
//     }
//   }
// `);

// Typography Components //
export const UsernameText = styled(Typography)`
  && {
    font-weight: ${FontSize.bold};
    cursor: default;
    padding: 6px 16px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const UsernameTextMobile = styled(Typography)`
  && {
    font-weight: ${FontSize.bold};
    cursor: default;
    padding: 6px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const StagingText = styled(Typography)`
  && {
    color: ${Color.black};
  }
`;

export const BadgeStyled = styling(Badge)({
  "& .MuiBadge-badge ": {
    fontSize: "0.6rem",
    fontWeight: "300",
    letterSpacing: "1.5px",

    borderRadius: "4px",
    padding: "0 0.215rem",
    margin: "0 0 1rem 0",
    height: "17px",
  },
});

export const HomepageButtonContainer = styling(Stack)({
  height: "3.5rem",
  zIndex: "-1",

  borderTop: `1.5px solid ${Color.black}`,
  borderBottom: `1.5px solid ${Color.black}`,
  background: `${Color.lightHiredlyPurple}`,
});

export const HomepageButton = styling(Link)((props) => ({
  cursor: "pointer",
  width: "100%",
  height: "3.5rem",
  color: `${Color.hiredlyPurple}`,

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "1rem",

  fontSize: "0.75rem",
  fontWeight: "500",

  letterSpacing: "0.09375rem",
  textTransform: "uppercase",
  textDecoration: "none",
  borderRight: props.jobs_button && `1.5px solid ${Color.black}`,

  [props.theme.breakpoints.down("sm")]: {
    gap: "0.5rem",
  },
}));

// NEW IMPLEMENTATION
export const AppbarStyled = styling(AppBar)((props) => ({
  backgroundColor: "hsla(0, 0%, 100%, 0.8)",
  backdropFilter: "blur(0.25rem)",
  height: "68px",

  [props.theme.breakpoints.down("lg")]: {
    height: props.$homepage === "true" ? "125px" : "68px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    boxShadow: "none",
    borderBottom: "1px solid lightgrey",
  },
}));

export const LogoStyled = styling("img")((props) => ({
  maxWidth: "132px",
  height: "20px",
  objectFit: "contain",
  paddingBottom: "5px",

  [props.theme.breakpoints.down("lg")]: {
    width: "110px",
    height: "32px",
    paddingBottom: "0px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    width: "128px",
    height: "37px",
  },
}));

export const VanillaGrid = styling(Grid)({
  //
});

export const TypographyStyled = styling(Typography)({
  //
});

export const ToolbarStyled = styling(Toolbar)((props) => ({
  display: "flex",
  justifyContent: "space-between",
  height: "68px",
  minHeight: "68px",

  "& .MuiToolbar-root": {
    minHeight: "68px",
  },

  [props.theme.breakpoints.between("tablet", "lg")]: {
    minHeight: "69px",
  },
}));

export const IconButtonStyled = styling(IconButton)({
  position: "relative",
});

export const ImgStyled = styling("img")((props) => ({
  width: 38,
  height: 27,

  [props.theme.breakpoints.down("lg")]: {
    width: 32,
    height: 22,
  },

  [props.theme.breakpoints.down("tablet")]: {
    width: 26,
    height: 18,
  },
}));

export const MYCountryFlag = styling(MYFlag, {
  shouldForwardProp: (propName) => propName !== "isPopper",
})((props) => ({
  height: props.isPopper ? "12px" : "27px",

  [props.theme.breakpoints.down("lg")]: {
    height: props.isPopper ? "12px" : "22px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    height: props.isPopper ? "12px" : "18px",
  },
}));

export const SGCountryFlag = styling(SGFlag, {
  shouldForwardProp: (propName) => propName !== "isPopper",
})((props) => ({
  height: props.isPopper ? "12px" : "27px",

  [props.theme.breakpoints.down("lg")]: {
    height: props.isPopper ? "12px" : "22px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    height: props.isPopper ? "12px" : "18px",
  },
}));

export const CountryOptionName = styling(Typography)((props) => ({
  fontSize: "14px",
  lineHeight: "24px",
  fontFamily: "Inter",
  fontWeight: "400",
  letterSpacing: "0.15px",

  [props.theme.breakpoints.down("lg")]: {
    fontSize: "14px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    fontSize: "12px",
  },
}));

export const ChevronDownStyled = styling(ChevronDownIcon)((props) => ({
  color: Color.black,
  width: 24,
  height: 24,

  [props.theme.breakpoints.down("tablet")]: {
    width: 13,
    height: 13,
  },
}));

export const ChevronUpStyled = styling(ChevronUpIcon)((props) => ({
  color: Color.black,
  width: 24,
  height: 24,

  [props.theme.breakpoints.down("tablet")]: {
    width: 13,
    height: 13,
  },
}));

export const LoginButton = styling(Button)((props) => ({
  width: "100px",
  height: "40px",
  fontSize: "16px",
  lineHeight: "24px",
  fontFamily: "Inter",
  fontWeight: "400",
  letterSpacing: "0.15px",
  padding: "0 16px",
  borderRadius: "8px",
  textTransform: "none",
  color: Color.hiredlyPurple,
  border: `1px solid ${Color.hiredlyPurple}`,
  backgroundColor: Color.white,

  "&:hover": {
    color: Color.white,
    backgroundColor: Color.hiredlyPurple,
  },

  [props.theme.breakpoints.down("lg")]: {
    fontSize: "12px",
    width: "80px",
    height: "32px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    padding: "0 24px",
    height: "24px",
    width: "60px",
    marginLeft: "20px",
    borderRadius: "100px",
    fontSize: "12px",
  },
}));

export const SignUpButton = styling(Button)((props) => ({
  width: "100px",
  height: "40px",
  fontSize: "16px",
  lineHeight: "24px",
  fontFamily: "Inter",
  fontWeight: "400",
  letterSpacing: "0.15px",
  padding: "0 16px",
  borderRadius: "8px",
  textTransform: "none",
  color: Color.white,
  backgroundColor: Color.hiredlyPurple,
  border: `1px solid ${Color.hiredlyPurple}`,

  "&:hover": {
    color: Color.hiredlyPurple,
    backgroundColor: Color.white,
  },

  [props.theme.breakpoints.down("lg")]: {
    fontSize: "12px",
    width: "80px",
    height: "32px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    display: "none",
  },
}));

export const TextButtonStyled = styling(Button)((props) => ({
  textTransform: "none",
  fontSize: "16px",
  lineHeight: "24px",
  fontFamily: "Inter",
  fontWeight: "400",
  letterSpacing: "0.15px",
  color: Color.hiredlyPurple,
  display: "flex",
  whiteSpace: "nowrap",

  [props.theme.breakpoints.down("lg")]: {
    fontSize: "12px",
  },

  [props.theme.breakpoints.down("tablet")]: {
    display: "none",
  },
}));

export const SearchInputContainer = styling(OutlinedInput)((props) => ({
  padding: "6px 8px 6px 16px",
  borderRadius: "80px",
  width: "360px",
  border: `1px solid ${Color.secondaryGrey}`,
  marginTop: "10px",
  marginBottom: "10px",
  height: "48px",
  fontSize: "16px",
  fontFamily: "Inter",
  lineHeight: "22px",
  backgroundColor: Color.white,

  "&:hover": {
    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.16)",
  },

  [props.theme.breakpoints.down("lg")]: {
    padding: "6px 3px 6px 16px",
    width: "257px",
    marginTop: "8px",
    marginBottom: "8px",
    height: "36px",
    fontSize: "13px",

    "& .MuiInputBase-input": {
      paddingLeft: "5px",
    },
  },

  [props.theme.breakpoints.down("tablet")]: {
    width: "192px",
    border: "none",
    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.16)",

    "& fieldset": {
      border: "none",
    },
  },
}));

export const MagnifyingGlassIconStyled = styling(MagnifyingGlassIcon)(
  (props) => ({
    width: "20px",
    height: "20px",

    [props.theme.breakpoints.down("lg")]: {
      width: "16px",
      height: "16px",
    },
  })
);

export const LinkContainer = styling(Grid)((props) => ({
  padding: "15px 0px",
  display: "flex",

  ":hover": {
    backgroundColor: Color.lightGrey,
  },
}));

export const LinkStyled = styling("a")((props) => ({
  textDecoration: "none",
  fontSize: "16px",
  fontFamily: "Inter",
  color: Color.black,
  fontWeight: "700",
}));

export const SparkleIconStyled = styling(SparklesIcon)((props) => ({
  color: Color.accentYellow,
}));

export const DrawerLoginButton = styling(Button)((props) => ({
  flex: 1,
  height: "43px",
  fontSize: "16px",
  lineHeight: "24px",
  fontFamily: "Inter",
  fontWeight: "400",
  letterSpacing: "0.15px",
  padding: "12px 24px",
  borderRadius: "30px",
  textTransform: "none",
  color: Color.hiredlyPurple,
  border: `1px solid ${Color.hiredlyPurple}`,
  backgroundColor: Color.white,
  maxWidth: "150px",

  "&:hover": {
    color: Color.white,
    backgroundColor: Color.hiredlyPurple,
  },
}));

export const DrawerSignUpButton = styling(Button)((props) => ({
  flex: 1,
  height: "43px",
  fontSize: "16px",
  lineHeight: "24px",
  fontFamily: "Inter",
  fontWeight: "400",
  letterSpacing: "0.15px",
  padding: "12px 24px",
  borderRadius: "30px",
  textTransform: "none",
  color: Color.white,
  backgroundColor: Color.hiredlyPurple,
  border: `1px solid ${Color.hiredlyPurple}`,
  maxWidth: "150px",

  "&:hover": {
    color: Color.hiredlyPurple,
    backgroundColor: Color.white,
  },
}));

export const AvatarStyled = styling(Avatar)((props) => ({
  width: "35px",
  height: "35px",
  cursor: "pointer",
}));

export const PopperStyled = styling(Popper)((props) => ({
  //
}));

export const GrowStyled = styling(Grow)((props) => ({
  zIndex: 20,
  width: "210px",
  marginRight: "20px",
  // transformOrigin: "center top",
}));

export const CountryGrowStyled = styling(Grow)((props) => ({
  zIndex: 20,
  marginTop: "25px",
  marginRight: "20px",
}));

export const ClickAwayListenerStyled = styling(ClickAwayListener)((props) => ({
  //
}));

export const SearchSuggestionContainer = styling(Grid)((props) => ({
  color: Color.black,
  cursor: "pointer",
  padding: "5px 10px",
  borderRadius: "30px",
  width: "100%",

  ":hover": {
    backgroundColor: Color.lightGrey,
    cursor: "pointer",
  },
}));

export const SearchDropdownContainer = styling(Grid)((props) => ({
  borderRadius: "10px",
  backgroundColor: Color.white,
  boxShadow: "0px 4px 19px rgba(0, 0, 0, 0.25)",
  width: "427px",
  padding: "20px",
  marginTop: "10px",
  position: "absolute",
  top: "50px",
  zIndex: "10px",

  [props.theme.breakpoints.down("lg")]: {
    width: "370px",
  },
}));

export const RecentSearchText = styling(Typography)((props) => ({
  fontSize: FontSize.desktopCaption,
  fontWeight: FontSize.bold,
  color: Color.black,
}));

export const ChipStyled = styling(Chip)((props) => ({
  backgroundColor: "rgba(81, 42, 204, 0.15)",
  padding: "5px 10px",
  margin: "10px 5px",
  cursor: "pointer",

  ":hover": {
    backgroundColor: "rgba(81, 42, 204, 0.3)",
  },

  ":focus": {
    backgroundColor: "rgba(81, 42, 204, 0.3)",
  },
}));

export const CountryMenuItem = styling(MenuItem)((props) => ({
  fontFamily: "Inter",
  fontSize: "12px",
  lineHeight: "24px",
  letterSpacing: "0.15px",
  columnGap: "10px",
  rowGap: "24px",
  width: "100%",
  cursor: "pointer",
  height: "auto",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  [props.theme.breakpoints.down("lg")]: {
    rowGap: "16px",
  },
}));

export const DrawerCountryToggleGrid = styling(Grid)((props) => ({
  borderRadius: "8px",
  overflow: "hidden",
  border: `1px solid ${Color.grey}`,
  transition: "height 0.3s ease",
  width: "100%",

  [props.theme.breakpoints.up("tablet")]: {
    width: "350px",
  },
}));
