import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Slider from "@mui/material/Slider";
import { useRouter } from "next/router";
import { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { calibrateSalary } from "../../../helpers/data_management";
import { updateCompanyFilter } from "../../../redux/actions/company_action";
import {
  getExperienceLevels,
  getJobTypes,
  getStateRegions,
  getTracks,
  updateJobListFilter,
  updateJobListFilterLanding,
} from "../../../redux/actions/job_action";
import { store } from "../../../redux/stores/store";
import {
  AccordianDetailsStyled,
  AccordianStyled,
  CheckboxStyled,
  ContentTextStyled,
  TitleStyled,
} from "./styles";

function valuetext(value) {
  return `RM${value}`;
}

function SharedMobileSearchFilters(props) {
  const router = useRouter();

  useEffect(() => {
    const stateRegions = store.getState().jobs.stateRegions;
    const jobTypes = store.getState().jobs.jobTypes;
    const tracks = store.getState().jobs.tracks;
    const experienceLevels = store.getState().jobs.experienceLevels;

    // Only recall graphQL to populate filters if redux param value is empty
    if (stateRegions.length == 0) {
      let params = {
        canCancel: false,
      };
      props.getStateRegions(params);
    }
    if (jobTypes.length == 0) {
      let params = {
        canCancel: false,
      };
      props.getJobTypes(params);
    }
    if (tracks.length == 0) {
      let params = {
        canCancel: false,
      };
      props.getTracks(params);
    }
    if (experienceLevels.length == 0) {
      let params = {
        canCancel: false,
      };
      props.getExperienceLevels(params);
    }
  }, []);

  // Job filters
  const getJobListFilter = () => {
    if (router.pathname === "/") {
      return store.getState().jobs.jobListFilterLanding;
    }

    return store.getState().jobs.jobListFilter;
  };
  const handleClickTracks = (value) => (e) => {
    let selectedJobTrackId = parseInt(value);
    let currentSelectedTrack = [];

    if (getJobListFilter()["track_ids"]) {
      currentSelectedTrack = JSON.parse(
        JSON.stringify(getJobListFilter()["track_ids"])
      );
    }

    if (selectedJobTrackId == -1) {
      currentSelectedTrack = [];
    } else if (currentSelectedTrack.indexOf(selectedJobTrackId) == -1) {
      currentSelectedTrack = currentSelectedTrack.concat(selectedJobTrackId);
    } else {
      currentSelectedTrack = currentSelectedTrack.filter((arrayValue) => {
        return arrayValue != selectedJobTrackId;
      });
    }

    jobFilterSelected("track_ids", currentSelectedTrack);
  };

  const isFilterSelected = (selectedFilters, filter) => {
    if (selectedFilters == undefined) {
      selectedFilters = [];
    }

    try {
      filter = parseInt(filter);
    } catch (error) {
      //
    }

    if (selectedFilters.indexOf(filter) == -1) {
      return false;
    } else {
      return true;
    }
  };

  const isFilterSelectedStateRegion = (selectedFilters, filter) => {
    if (selectedFilters == undefined) {
      selectedFilters = [];
    }

    if (selectedFilters.indexOf(filter) == -1) {
      return false;
    } else {
      return true;
    }
  };

  const handleClickStates = (value) => (e) => {
    let selectedJobStateRegion = value;
    let currentSelectedStateRegion = [];

    if (getJobListFilter()["state_region_names"]) {
      currentSelectedStateRegion = JSON.parse(
        JSON.stringify(getJobListFilter()["state_region_names"])
      );
    }

    if (selectedJobStateRegion == -1) {
      currentSelectedStateRegion = [];
    } else if (
      currentSelectedStateRegion.indexOf(selectedJobStateRegion) == -1
    ) {
      currentSelectedStateRegion = currentSelectedStateRegion.concat(
        selectedJobStateRegion
      );
    } else {
      currentSelectedStateRegion = currentSelectedStateRegion.filter(
        (arrayValue) => {
          return arrayValue != selectedJobStateRegion;
        }
      );
    }

    jobFilterSelected("state_region_names", currentSelectedStateRegion);
  };
  const handleClickJobTypes = (value) => (e) => {
    let selectedJobType = parseInt(value);
    let currentSelectedJobType = [];

    if (getJobListFilter()["job_type_ids"]) {
      currentSelectedJobType = JSON.parse(
        JSON.stringify(getJobListFilter()["job_type_ids"])
      );
    }

    if (selectedJobType == -1) {
      currentSelectedJobType = [];
    } else if (currentSelectedJobType.indexOf(selectedJobType) == -1) {
      currentSelectedJobType = currentSelectedJobType.concat(selectedJobType);
    } else {
      currentSelectedJobType = currentSelectedJobType.filter((arrayValue) => {
        return arrayValue != selectedJobType;
      });
    }

    jobFilterSelected("job_type_ids", currentSelectedJobType);
  };
  const handleClickJobExperience = (value) => (e) => {
    let selectedJobExperience = parseInt(value);
    let currentSelectedJobExperience = [];

    if (getJobListFilter()["experience_ids"]) {
      currentSelectedJobExperience = JSON.parse(
        JSON.stringify(getJobListFilter()["experience_ids"])
      );
    }

    if (selectedJobExperience == -1) {
      currentSelectedJobExperience = [];
    } else if (
      currentSelectedJobExperience.indexOf(selectedJobExperience) == -1
    ) {
      currentSelectedJobExperience = currentSelectedJobExperience.concat(
        selectedJobExperience
      );
    } else {
      currentSelectedJobExperience = currentSelectedJobExperience.filter(
        (arrayValue) => {
          return arrayValue != selectedJobExperience;
        }
      );
    }

    jobFilterSelected("experience_ids", currentSelectedJobExperience);
  };

  const getSalaryRange = (jobListFilterLanding) => {
    return jobListFilterLanding["expected_salary"]
      ? jobListFilterLanding["expected_salary"]
      : 0;
  };

  const handleSliderChange = (e, newSalary) => {
    jobFilterSelected("expected_salary", newSalary);
  };

  const jobFilterSelected = (key, value) => {
    let currentLandingFilter = JSON.parse(JSON.stringify(getJobListFilter()));

    if (key == "expected_salary") {
      currentLandingFilter[key] = value;
    } else {
      currentLandingFilter[key] = [...value];
    }

    if (router.pathname === "/") {
      props.updateJobListFilterLanding(currentLandingFilter);
    } else {
      props.updateJobListFilter(currentLandingFilter);
    }
  };

  const onCompanyFilterChange = (type, value) => (e) => {
    changeCompanyFilter(type, value);
  };

  const changeCompanyFilter = (key, value) => {
    let companyFilter = JSON.parse(
      JSON.stringify(store.getState().companies.companyFilter)
    );

    if (key != "state_regions") {
      value = parseInt(value);
    }

    if (value == -1) {
      companyFilter[key] = []; //All is selected
    } else {
      if (companyFilter[key]) {
        if (companyFilter[key].indexOf(value) != -1) {
          companyFilter[key] = companyFilter[key].filter((filterValue) => {
            return filterValue != value;
          });
        } else {
          companyFilter[key].push(value);
        }
      } else {
        companyFilter[key] = [value];
      }
    }

    props.updateCompanyFilter(companyFilter);
  };

  const renderJobMobileFilter = () => {
    let specializationCount = getJobListFilter()["track_ids"]?.length;
    let stateCount = getJobListFilter()["state_region_names"]?.length;
    let jobtypeCount = getJobListFilter()["job_type_ids"]?.length;
    let experienceLevelCount = getJobListFilter()["experience_ids"]?.length;
    let salaryCount = calibrateSalary(getJobListFilter()["expected_salary"]);

    return (
      <Fragment>
        {/* Job specialization filter section */}
        <AccordianStyled elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="job-specialization-content"
            id="job-specialization-header"
          >
            <TitleStyled>
              specialisations
              {`${
                specializationCount == undefined || specializationCount == 0
                  ? " - all"
                  : `- ${specializationCount}`
              }`}
            </TitleStyled>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup>
              <FormControlLabel
                key={-1}
                value={-1}
                control={
                  <CheckboxStyled
                    onChange={handleClickTracks(-1)}
                    name={"All"}
                    checked={
                      getJobListFilter()["track_ids"] &&
                      getJobListFilter()["track_ids"].length > 0
                        ? false
                        : true
                    }
                  />
                }
                label={"All"}
              />
              {props?.tracks?.map((track, index) => (
                <FormControlLabel
                  key={track?.id}
                  value={track?.id}
                  control={
                    <CheckboxStyled
                      onChange={handleClickTracks(track?.id)}
                      name={track?.title}
                      checked={isFilterSelected(
                        getJobListFilter()["track_ids"],
                        track?.id
                      )}
                    />
                  }
                  label={track?.title}
                />
              ))}
            </FormGroup>
          </AccordionDetails>
        </AccordianStyled>
        {/* Job state region filter section */}
        <AccordianStyled elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="job-state-region-content"
            id="job-state-region-header"
          >
            <TitleStyled>
              states
              {`${
                stateCount == undefined || stateCount == 0
                  ? " - all"
                  : `- ${stateCount}`
              }`}
            </TitleStyled>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup>
              <FormControlLabel
                key={-1}
                value={-1}
                control={
                  <CheckboxStyled
                    onChange={handleClickStates(-1)}
                    name={"All"}
                    checked={
                      getJobListFilter()["state_region_names"] &&
                      getJobListFilter()["state_region_names"].length > 0
                        ? false
                        : true
                    }
                  />
                }
                label={"All"}
              />
              {props.stateRegions.map((stateRegion, index) => (
                <FormControlLabel
                  key={stateRegion.id}
                  value={stateRegion.state}
                  control={
                    <CheckboxStyled
                      onChange={handleClickStates(stateRegion.state)}
                      name={stateRegion.state}
                      checked={isFilterSelectedStateRegion(
                        getJobListFilter()["state_region_names"],
                        stateRegion.state
                      )}
                    />
                  }
                  label={stateRegion.state}
                />
              ))}
            </FormGroup>
          </AccordionDetails>
        </AccordianStyled>
        {/* Job type filter section */}
        <AccordianStyled elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="job-type-content"
            id="job-type-header"
          >
            <TitleStyled>
              job types
              {`${
                jobtypeCount == undefined || jobtypeCount == 0
                  ? " - all"
                  : `- ${jobtypeCount}`
              }`}
            </TitleStyled>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup>
              <FormControlLabel
                key={-1}
                value={-1}
                control={
                  <CheckboxStyled
                    onChange={handleClickJobTypes(-1)}
                    name={"All"}
                    checked={
                      getJobListFilter()["job_type_ids"] &&
                      getJobListFilter()["job_type_ids"].length > 0
                        ? false
                        : true
                    }
                  />
                }
                label={"All"}
              />
              {props.jobTypes.map((jobType, index) => (
                <FormControlLabel
                  key={jobType.id}
                  value={jobType.id}
                  control={
                    <CheckboxStyled
                      onChange={handleClickJobTypes(jobType.id)}
                      name={jobType.title}
                      checked={isFilterSelected(
                        getJobListFilter()["job_type_ids"],
                        jobType.id
                      )}
                    />
                  }
                  label={jobType.title}
                />
              ))}
            </FormGroup>
          </AccordionDetails>
        </AccordianStyled>
        {/* Job experience filter section */}
        <AccordianStyled elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="job-experience-content"
            id="job-experience-header"
          >
            <TitleStyled>
              experience levels
              {`${
                experienceLevelCount == undefined || experienceLevelCount == 0
                  ? " - all"
                  : `- ${experienceLevelCount}`
              }`}
            </TitleStyled>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup>
              <FormControlLabel
                key={-1}
                value={-1}
                control={
                  <CheckboxStyled
                    onChange={handleClickJobExperience(-1)}
                    name={"All"}
                    checked={
                      getJobListFilter()["experience_ids"] &&
                      getJobListFilter()["experience_ids"].length > 0
                        ? false
                        : true
                    }
                  />
                }
                label={"All"}
              />
              {props.experienceLevels.map((experienceLevel, index) => (
                <FormControlLabel
                  key={experienceLevel.id}
                  value={experienceLevel.id}
                  control={
                    <CheckboxStyled
                      onChange={handleClickJobExperience(experienceLevel.id)}
                      name={experienceLevel.title}
                      checked={isFilterSelected(
                        getJobListFilter()["experience_ids"],
                        experienceLevel.id
                      )}
                    />
                  }
                  label={experienceLevel.title}
                />
              ))}
            </FormGroup>
          </AccordionDetails>
        </AccordianStyled>
        {/* Job salary range filter section */}
        <AccordianStyled elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="job-salary-content"
            id="job-salary-header"
          >
            <TitleStyled>
              salary
              {`${
                salaryCount == undefined || salaryCount == 0
                  ? ""
                  : `${" "}- RM ${salaryCount}`
              }`}
            </TitleStyled>
          </AccordionSummary>
          <AccordianDetailsStyled>
            <ContentTextStyled>minimum monthly salary</ContentTextStyled>
            <Slider
              defaultValue={0}
              getAriaValueText={valuetext}
              aria-labelledby="salary-range-slider"
              step={1}
              marks
              min={0}
              max={50}
              onChange={handleSliderChange}
              value={getSalaryRange(getJobListFilter())}
            />
            <ContentTextStyled>
              RM {calibrateSalary(getJobListFilter()["expected_salary"])}
            </ContentTextStyled>
          </AccordianDetailsStyled>
        </AccordianStyled>
      </Fragment>
    );
  };

  const renderCompanyMobileFilter = () => {
    return (
      <Fragment>
        <AccordianStyled elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <TitleStyled>industry</TitleStyled>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup>
              <FormControlLabel
                key={-1}
                control={
                  <CheckboxStyled
                    checked={
                      props.companyFilter["industry_ids"] &&
                      props.companyFilter["industry_ids"].length > 0
                        ? false
                        : true
                    }
                    onChange={onCompanyFilterChange("industry_ids", -1)}
                    name="checkedA"
                  />
                }
                label={"All"}
              />
              {props.industries.map((industry) => (
                <FormControlLabel
                  key={industry.id}
                  control={
                    <CheckboxStyled
                      checked={isFilterSelected(
                        props.companyFilter["industry_ids"],
                        industry.id
                      )}
                      onChange={onCompanyFilterChange(
                        "industry_ids",
                        industry.id
                      )}
                      name="checkedA"
                    />
                  }
                  label={industry.title}
                />
              ))}
            </FormGroup>
          </AccordionDetails>
        </AccordianStyled>
        <AccordianStyled elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <TitleStyled>location</TitleStyled>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup>
              <FormControlLabel
                key={-1}
                control={
                  <CheckboxStyled
                    checked={
                      props.companyFilter["state_regions"] &&
                      props.companyFilter["state_regions"].length > 0
                        ? false
                        : true
                    }
                    onChange={onCompanyFilterChange("state_regions", -1)}
                    name="checkedA"
                  />
                }
                label={"All"}
              />
              {props.stateRegions.map((stateRegion) => (
                <FormControlLabel
                  key={stateRegion.id}
                  control={
                    <CheckboxStyled
                      checked={isFilterSelectedStateRegion(
                        props.companyFilter["state_regions"],
                        stateRegion.state
                      )}
                      onChange={onCompanyFilterChange(
                        "state_regions",
                        stateRegion.state
                      )}
                      name="checkedA"
                    />
                  }
                  label={stateRegion.state}
                />
              ))}
            </FormGroup>
          </AccordionDetails>
        </AccordianStyled>
        <AccordianStyled elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <TitleStyled>company size</TitleStyled>
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup>
              <FormControlLabel
                key={-1}
                control={
                  <CheckboxStyled
                    checked={
                      props.companyFilter["company_sizes"] &&
                      props.companyFilter["company_sizes"].length > 0
                        ? false
                        : true
                    }
                    onChange={onCompanyFilterChange("company_sizes", -1)}
                    name="checkedA"
                  />
                }
                label={"All"}
              />
              {props.companySizes.map((companySize) => (
                <FormControlLabel
                  key={companySize.id}
                  control={
                    <CheckboxStyled
                      checked={isFilterSelected(
                        props.companyFilter["company_sizes"],
                        companySize.id
                      )}
                      onChange={onCompanyFilterChange(
                        "company_sizes",
                        companySize.id
                      )}
                      name="checkedA"
                    />
                  }
                  label={companySize.title}
                />
              ))}
            </FormGroup>
          </AccordionDetails>
        </AccordianStyled>
      </Fragment>
    );
  };

  const renderMobileFilter = () => {
    switch (router.pathname) {
      case "/":
      case "/jobs":
      case "/job-search/[filter]":
      case "/job-search/[filter]/[secondFilter]": {
        return renderJobMobileFilter();
      }
      case "/companies": {
        return renderCompanyMobileFilter();
      }
      case "/job_list/[slug]": {
        return renderJobMobileFilter();
      }
      case "/jobs/[id]": {
        return renderJobMobileFilter();
      }
      case "/companies/[id]": {
        return renderCompanyMobileFilter();
      }
    }
  };

  return <div>{renderMobileFilter()}</div>;
}

const mapStateToProps = (state) => {
  return {
    tracks: state.jobs.tracks,
    stateRegions: state.jobs.stateRegions,
    industries: state.companies.industries,
    companySizes: state.companies.companySizes,
    jobTypes: state.jobs.jobTypes,
    experienceLevels: state.jobs.experienceLevels,
    jobListFilterLanding: state.jobs.jobListFilterLanding,
    jobListFilter: state.jobs.jobListFilter,
    companyFilter: state.companies.companyFilter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTracks: bindActionCreators(getTracks, dispatch),
    getStateRegions: bindActionCreators(getStateRegions, dispatch),
    getJobTypes: bindActionCreators(getJobTypes, dispatch),
    getExperienceLevels: bindActionCreators(getExperienceLevels, dispatch),
    updateJobListFilterLanding: bindActionCreators(
      updateJobListFilterLanding,
      dispatch
    ),
    updateCompanyFilter: bindActionCreators(updateCompanyFilter, dispatch),
    updateJobListFilter: bindActionCreators(updateJobListFilter, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SharedMobileSearchFilters);
