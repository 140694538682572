import { styled as matStyled } from "@mui/system";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Lottie from "react-lottie-player";
import Color from "../../../../assets/colors";
import Grid from "@mui/material/Grid";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import LinearProgress from "@mui/material/LinearProgress";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

// Title
export const UploadResumeTitle = matStyled(Typography)(({ theme }) => ({
  marginTop: "26px",
  fontFamily: "Inter",
  fontWeight: 700,
  fontStyle: "normal",
  fontSize: "25px",
  lineHeight: "32px",

  [theme.breakpoints.between("xs", 768)]: {
    fontSize: "20px",
    lineHeight: "24px",
  },
}));

export const UploadResumeSubTitle = matStyled(Typography)(({ theme }) => ({
  marginTop: "10px",
  fontFamily: "Inter",
  fontWeight: 400,
  fontStyle: "normal",
  fontSize: "14px",
  lineHeight: "22px",

  [theme.breakpoints.between("xs", 768)]: {
    fontSize: "12px",
    lineHeight: "16.8px",
  },
}));

export const UploadResumeContainer = matStyled("form")((props: any) => ({
  position: "relative",
  marginTop: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  minHeight: props.visitor ? "184px" : "341px",
  background: Color.lightGrey,
  border: `1px dashed ${Color.darkGrey}`,
  borderRadius: "20px",
  minWidth: "360px",

  [props.theme.breakpoints.down(768)]: {
    minWidth: "328px",
  },
}));

export const InputStyled = matStyled("input")({
  display: "none",
});

export const DragResumeElement = matStyled("div")({
  position: "absolute",
  width: "100%",
  height: "100%",
  top: "0px",
  right: "0px",
  bottom: "0px",
  left: "0px",
  zIndex: 2,
});

export const LottieStyled = matStyled(Lottie)((props: any) => ({
  width: props.visitor ? "220px" : "122px",
  height: props.visitor ? "140px" : "93px",
}));

export const ImportButton = matStyled(Button)({
  marginTop: "16px",
  color: Color.white,
  backgroundColor: Color.hiredlyPurple,
  borderRadius: "100px",
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "19px",
  textTransform: "none",
  padding: "10.5px 18.5px",
  width: "200px",

  "&:hover": {
    backgroundColor: Color.hiredlyPurple,
  },
});

export const OrContainer = matStyled("div")({
  marginTop: "16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
});

export const Dash = matStyled("div")({
  width: "221px",
  height: "1px",
  background: Color.secondaryGrey,
});

export const OrText = matStyled(Typography)({
  padding: "0 10px",
  fontFamily: "Inter",
  fontWeight: 400,
  fontStyle: "normal",
  fontSize: "14px",
  lineHeight: "20px",
  color: "rgba(0, 0, 0, 0.6)",
});

export const DropResumeContainer = matStyled("div")({
  marginTop: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
});

export const DropText = matStyled(Typography)({
  display: "flex",
  fontFamily: "Inter",
  fontWeight: 700,
  fontStyle: "normal",
  fontSize: "16px",
  lineHeight: "22px",
  color: "rgba(0, 0, 0, 0.87)",
  letterSpacing: "0.5px",
});

export const DropSubText = matStyled(Typography)({
  marginLeft: "4px",
  fontFamily: "Inter",
  fontWeight: 400,
  fontStyle: "normal",
  fontSize: "16px",
  lineHeight: "22px",
  color: "rgba(0, 0, 0, 0.87)",
});

export const DropTextPurple = matStyled(Typography)({
  marginLeft: "4px",
  fontFamily: "Inter",
  fontWeight: 700,
  fontStyle: "normal",
  textDecoration: "underline",
  fontSize: "16px",
  lineHeight: "22px",
  color: Color.hiredlyPurple,
  cursor: "pointer",
});

export const SupportFileText = matStyled(Typography)((props) => ({
  marginTop: "12px",
  fontFamily: "Inter",
  fontWeight: 400,
  fontStyle: "normal",
  fontSize: "0.875rem",
  lineHeight: "22px",
  color: "rgba(0, 0, 0, 0.6)",

  [props.theme.breakpoints.down(768)]: {
    fontSize: "14px",
  },
}));

export const EmptyContainer = matStyled(Grid)({});

export const UploadFileOutlinedIconStyled = matStyled(UploadFileOutlinedIcon)({
  fontSize: "30px",
});

export const CloseIconStyled = matStyled(CloseIcon)({
  fontSize: "30px",
  color: "#BE4242",
});

export const ErrorOutlineIconStyled = matStyled(ErrorOutlineIcon)({
  fontSize: "20px",
  color: "#BE4242",
});

export const LinearProgressStyled = matStyled(LinearProgress)({
  borderRadius: "10px",
});

export const FilenameText = matStyled(Typography)({
  fontSize: "14px",
  fontWeight: "400",
});

export const PercentageText = matStyled(Typography)({
  fontSize: "14px",
  fontWeight: "400",
  color: Color.hiredlyPurple,
});

export const DoneOutlinedIconStyled = matStyled(DoneOutlinedIcon)({
  color: "#90B938",
  fontSize: "30px",
});

export const OuterContainer = matStyled(Grid)((props) => ({
  position: "relative",
  marginTop: "16px",
  display: "flex",
  flexDirection: "row",
  width: "100%",
  background: Color.lightGrey,
  border: `1px dashed ${Color.darkGrey}`,
  borderRadius: "20px",
  minWidth: "360px",
  paddingBlock: "20px 30px",
  minHeight: "184px",

  [props.theme.breakpoints.down(768)]: {
    minWidth: "256px",
    minHeight: "auto",
    flexDirection: "column",
  },
}));

export const InvalidResumeText = matStyled(Typography)({
  color: "#BE4242",
  fontStyle: "italic",
  fontSize: "12px",
});

export const ErrorContainer = matStyled(Grid)({
  gap: "15px",
  padding: "2px",
  flexWrap: "nowrap",
  marginLeft: "10px",
  marginRight: "10px",
  backgroundColor: "#BE42421A",
  border: "1px solid #BE4242",
  borderRadius: "8px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
});
