import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import * as config from "../../../config/config";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import {
  updateResumeConfirmationDialog,
  updateSignUpProfileDialog,
} from "../../../redux/actions/dialog_action";
import {
  ContentContainer,
  ContentText,
  ContentTextContainer,
  ContentWrapper,
  DialogStyled,
  HeaderTitle,
  NoResumeImage,
  ResumeBuilderImage,
  Title,
} from "./styles";

export default function ResumeConfirmationDialog(props: any) {
  const router = useRouter();
  const open = useSelector(
    (state: any) => state?.dialog?.showResumeConfirmationDialog
  );
  const dispatch = useDispatch();

  function handleOpenProfilePreviewDialog() {
    sendTrackingEvent({
      event: "CE_click-have-resume-onboarding",
    });
    // @ts-ignore
    dispatch(updateResumeConfirmationDialog(false));
    // @ts-ignore
    dispatch(updateSignUpProfileDialog(true));
  }

  function handleOnboardingClick() {
    sendTrackingEvent({
      event: "CE_click-no-resume-onboarding",
    });

    // @ts-ignore
    dispatch(updateResumeConfirmationDialog(false));
    router.replace("/onboarding");
  }

  return (
    <DialogStyled open={open}>
      <HeaderTitle>Do you have a resume?</HeaderTitle>
      <ContentWrapper>
        <ContentContainer onClick={handleOpenProfilePreviewDialog}>
          <ResumeBuilderImage
            alt="Resume builder image"
            src={`${config.assetDomain}/images/hiredly/resume+builder.png`}
          />
          <ContentTextContainer>
            <Title>I have a Resume</Title>
            <ContentText>
              Upload your resume and we will handle the rest for you.
            </ContentText>
          </ContentTextContainer>
        </ContentContainer>

        <ContentContainer onClick={handleOnboardingClick}>
          <NoResumeImage
            alt="No resume image"
            src={`${config.assetDomain}/images/hiredly/no+resume.png`}
          />
          <ContentTextContainer>
            <Title>I don’t have a Resume</Title>
            <ContentText>
              Tell us about yourself, we'll recommend you the right jobs.
            </ContentText>
          </ContentTextContainer>
        </ContentContainer>
      </ContentWrapper>
    </DialogStyled>
  );
}
